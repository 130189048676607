/**
 * @license
 *
 * Font Family: Pilcrow Rounded
 * Designed by: Satya Rajpurohit
 * URL: https://www.fontshare.com/fonts/pilcrow-rounded
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Pilcrow Rounded Variable(Variable font)
 * Pilcrow Rounded Regular
 * Pilcrow Rounded Medium
 * Pilcrow Rounded Semibold
 * Pilcrow Rounded Bold
 * Pilcrow Rounded Heavy
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 400.0;
*
* available axes:

* 'wght' (range from 400.0 to 900.0)

*/

@font-face {
  font-family: 'PilcrowRounded-var';
  src: url('./PilcrowRounded-Variable.woff2') format('woff2'),
    url('./PilcrowRounded-Variable.woff') format('woff'),
    url('./PilcrowRounded-Variable.ttf') format('truetype');
  font-weight: 400 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'PilcrowRounded';
  src: url('./PilcrowRounded-Regular.woff2') format('woff2'),
    url('./PilcrowRounded-Regular.woff') format('woff'),
    url('./PilcrowRounded-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'PilcrowRounded';
  src: url('./PilcrowRounded-Medium.woff2') format('woff2'),
    url('./PilcrowRounded-Medium.woff') format('woff'),
    url('./PilcrowRounded-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'PilcrowRounded';
  src: url('./PilcrowRounded-Semibold.woff2') format('woff2'),
    url('./PilcrowRounded-Semibold.woff') format('woff'),
    url('./PilcrowRounded-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'PilcrowRounded';
  src: url('./PilcrowRounded-Bold.woff2') format('woff2'),
    url('./PilcrowRounded-Bold.woff') format('woff'),
    url('./PilcrowRounded-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'PilcrowRounded';
  src: url('./PilcrowRounded-Heavy.woff2') format('woff2'),
    url('./PilcrowRounded-Heavy.woff') format('woff'),
    url('./PilcrowRounded-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
