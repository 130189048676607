@font-face {
  font-family: PilcrowRounded-var;
  src: url("PilcrowRounded-Variable.3476064a.woff2") format("woff2"), url("PilcrowRounded-Variable.780860d3.woff") format("woff"), url("PilcrowRounded-Variable.8b6a21a8.ttf") format("truetype");
  font-weight: 400 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: PilcrowRounded;
  src: url("PilcrowRounded-Regular.1261e96d.woff2") format("woff2"), url("PilcrowRounded-Regular.cf4f5787.woff") format("woff"), url("PilcrowRounded-Regular.8e3c4650.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: PilcrowRounded;
  src: url("PilcrowRounded-Medium.3d40079c.woff2") format("woff2"), url("PilcrowRounded-Medium.2569d041.woff") format("woff"), url("PilcrowRounded-Medium.5e921b5f.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: PilcrowRounded;
  src: url("PilcrowRounded-Semibold.c57f61a3.woff2") format("woff2"), url("PilcrowRounded-Semibold.38bcc5c8.woff") format("woff"), url("PilcrowRounded-Semibold.8ba22014.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: PilcrowRounded;
  src: url("PilcrowRounded-Bold.dbdf25f2.woff2") format("woff2"), url("PilcrowRounded-Bold.f612ac23.woff") format("woff"), url("PilcrowRounded-Bold.bd4063db.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: PilcrowRounded;
  src: url("PilcrowRounded-Heavy.37815e74.woff2") format("woff2"), url("PilcrowRounded-Heavy.da637ef6.woff") format("woff"), url("PilcrowRounded-Heavy.fa269319.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

/*# sourceMappingURL=index.639c1fb8.css.map */
